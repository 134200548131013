<script>
  import {
    SimpleBar
  } from "simplebar-vue3";
  
  import i18n from "../i18n";

  // import SocketMixins from "../mixins/SocketMixins";
  import dayjs from 'dayjs';
  import relativeTime from 'dayjs/plugin/relativeTime';
import axios from "axios";
  // import calendar from 'dayjs/plugin/calendar';
  import { inject } from 'vue';

  dayjs.extend(relativeTime);
  
  /**
   * Nav-bar Component
   */
  export default {
    // mixins: [SocketMixins],
    data() {
      return {
        languages: [{
            flag: require("@/assets/images/flags/us.svg"),
            language: "en",
            title: "English",
          },
          {
            flag: require("@/assets/images/flags/spain.svg"),
            language: "sp",
            title: "Española",
          },
          {
            flag: require("@/assets/images/flags/germany.svg"),
            language: "gr",
            title: "Deutsche",
          },
          {
            flag: require("@/assets/images/flags/italy.svg"),
            language: "it",
            title: "italiana",
          },
          {
            flag: require("@/assets/images/flags/russia.svg"),
            language: "ru",
            title: "русский",
          },
          {
            flag: require("@/assets/images/flags/china.svg"),
            language: "ch",
            title: "中國人",
          },
          {
            flag: require("@/assets/images/flags/french.svg"),
            language: "fr",
            title: "Français",
          },
          ],
        lan: i18n.locale,
        text: null,
        flag: null,
        value: null,
        myVar: 1,
        user: '',
        avatar: '',
        role: '',
        notifData: [],
        statusActivate: null,
        isShowStatus: false
      };
    },
    components: {
      SimpleBar
    },
    setup() {
      const syncContact = inject("sync_contact")
      return { syncContact }
    },
    methods: {
      toggleHamburgerMenu() {
        var windowSize = document.documentElement.clientWidth;
  
        if (windowSize > 767)
          document.querySelector(".hamburger-icon").classList.toggle("open");
  
        //For collapse horizontal menu
        if (
          document.documentElement.getAttribute("data-layout") === "horizontal"
        ) {
          document.body.classList.contains("menu") ?
            document.body.classList.remove("menu") :
            document.body.classList.add("menu");
        }
  
        //For collapse vertical menu
        if (document.documentElement.getAttribute("data-layout") === "vertical") {
          if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
              document.documentElement.setAttribute("data-sidebar-size", "") :
              document.documentElement.setAttribute("data-sidebar-size", "sm");
          } else if (windowSize > 1025) {
            document.body.classList.remove("vertical-sidebar-enable");
            document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
              document.documentElement.setAttribute("data-sidebar-size", "sm") :
              document.documentElement.setAttribute("data-sidebar-size", "lg");
          } else if (windowSize <= 767) {
            document.body.classList.add("vertical-sidebar-enable");
            document.documentElement.setAttribute("data-sidebar-size", "lg");
          }
        }
  
        //Two column menu
        if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
          document.body.classList.contains("twocolumn-panel") ?
            document.body.classList.remove("twocolumn-panel") :
            document.body.classList.add("twocolumn-panel");
        }
      },
      toggleMenu() {
        this.$parent.toggleMenu();
      },
      toggleRightSidebar() {
        this.$parent.toggleRightSidebar();
      },
      initFullScreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
          !document.fullscreenElement &&
          /* alternative standard method */
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement
        ) {
          // current working methods
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
              Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
      },
      setLanguage(locale, country, flag) {
        this.lan = locale;
        this.text = country;
        this.flag = flag;
        document.getElementById("header-lang-img").setAttribute("src", flag);
        i18n.global.locale = locale;
      },
      toggleDarkMode() {
        if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
          document.documentElement.setAttribute("data-layout-mode", "light");
          sessionStorage.setItem("data-layout-mode", "light");
        } else {
          document.documentElement.setAttribute("data-layout-mode", "dark");
          sessionStorage.setItem("data-layout-mode", "dark");
        }
      },
      initTopbarComponents() {
        function updateCartPrice() {
          var currencySign = "$";
          var subtotal = 0;
          Array.from(document.getElementsByClassName("cart-item-price")).forEach(function (e) {
            subtotal += parseFloat(e.innerHTML);
          });
          if (document.getElementById("cart-item-total")) {
            document.getElementById("cart-item-total").innerHTML = currencySign + subtotal.toFixed(2);
          }
        }
        if (document.getElementsByClassName("dropdown-item-cart")) {
          var dropdownItemCart = document.querySelectorAll(".dropdown-item-cart").length;
          Array.from(document.querySelectorAll("#page-topbar .dropdown-menu-cart .remove-item-btn")).forEach(function (item) {
            item.addEventListener("click", function () {
              dropdownItemCart--;
              this.closest(".dropdown-item-cart").remove();
              Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
                e.innerHTML = dropdownItemCart;
              });
              updateCartPrice();
              if (document.getElementById("empty-cart")) {
                document.getElementById("empty-cart").style.display = dropdownItemCart == 0 ? "block" : "none";
              }
              if (document.getElementById("checkout-elem")) {
                document.getElementById("checkout-elem").style.display = dropdownItemCart == 0 ? "none" : "block";
              }
            });
          });
          Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
            e.innerHTML = dropdownItemCart;
          });
          if (document.getElementById("empty-cart")) {
            document.getElementById("empty-cart").style.display = "none";
          }
          if (document.getElementById("checkout-elem")) {
            document.getElementById("checkout-elem").style.display = "block";
          }
          updateCartPrice();
        }
  
        // notification messages
        if (document.getElementsByClassName("notification-check")) {
          Array.from(document.querySelectorAll(".notification-check input")).forEach(function (element) {
            element.addEventListener("click", function (el) {
              el.target.closest(".notification-item").classList.toggle("active");
            });
          });
        }
      },
      chatTime(timestamp){
        var dateTime = new Date(parseInt(timestamp) * 1000);
        return dayjs().to(dayjs(dateTime));
      },
      sortNotifData(){
        this.notifData.sort((a, b) => b.timestamp - a.timestamp);
      },
      intervalOnGetNotif()
      {
        setInterval(() => {
          if(localStorage.getItem("notif") !== null){
            this.notifData = JSON.parse(localStorage.getItem("notif"))
            this.sortNotifData();
          } else {
            this.notifData = [];
          }
        }, 1500)
      },
      openChat(user){
        let phone = user.from.split("@")[0]
        this.$router.push({
          path: "/chat/" + phone,
        });
      },
      async setStatus(e){
        let status = 0;
        if(e.target.checked){
          status = 1;
        }
        let secureParam = "?ClientID="+process.env.VUE_APP_CLIENT_ID+"&AccessToken="+localStorage.getItem("jwt");
        let url = process.env.VUE_APP_URL_API+"agent-api/setStatus"+secureParam;
        let formData = new FormData();
        formData.append("Status", status);
        let reqSetStatus = await axios.post(url, formData)
        if(reqSetStatus.status === 200){
          let data = reqSetStatus.data;
          if(data.Meta.Code === 200){
            localStorage.removeItem("userdata")
            localStorage.setItem("userdata", JSON.stringify(data.Data));
          }
        }
      }
    },
    created() {
      this.intervalOnGetNotif();
      
      const userData = JSON.parse(localStorage.getItem("user"));
      this.user = userData.Username
      this.avatar = userData.Avatar ? userData.Avatar.Original : require('@/assets/images/users/user-dummy-img.jpg')
      this.role = userData.Role.Title
    },
    beforeMount() {
      console.log("before mounted");
      this.notifData = this._notifData;
    },
    mounted() {
      document.addEventListener("scroll", function () {
        var pageTopbar = document.getElementById("page-topbar");
        if (pageTopbar) {
          document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
            "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
        }
      });
      if (document.getElementById("topnav-hamburger-icon"))
        document
          .getElementById("topnav-hamburger-icon")
          .addEventListener("click", this.toggleHamburgerMenu);
  
      this.initTopbarComponents();

      if(this.notifData.length !== 0){
        this.notifData = JSON.parse(localStorage.getItem("notif"));
        this.sortNotifData();
      }

      document.documentElement.setAttribute("data-layout-mode", sessionStorage.getItem("data-layout-mode"));

      let user = localStorage.getItem("userdata")
      if(user){
        let userJson = JSON.parse(user);
        this.statusActivate = userJson.StatusActive;

        if(userJson.Role.Code == 'agent'){
          this.isShowStatus = true;
        } else {
          this.isShowStatus = false;
        }
      }
    }
  };
  </script>
  
  <template>
    <header id="page-topbar">
      <div class="layout-width">
        <div class="navbar-header">
          <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box horizontal-logo">
              <router-link to="/" class="logo logo-dark">
                <span class="logo-sm">
                  <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span class="logo-lg">
                  <img src="@/assets/images/logo-dark.png" alt="" height="17" />
                </span>
              </router-link>
  
              <router-link to="/" class="logo logo-light">
                <span class="logo-sm">
                  <img src="@/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span class="logo-lg">
                  <img src="@/assets/images/logo-light.png" alt="" height="17" />
                </span>
              </router-link>
            </div>
  
            <button type="button" class="
                btn btn-sm
                px-3
                fs-16
                header-item
                vertical-menu-btn
                topnav-hamburger shadow-none
              " id="topnav-hamburger-icon">
              <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
  
            <!-- Indicator online -->
          </div>
  
          <div class="d-flex align-items-center">

            <div class="dropdown d-md-none topbar-head-dropdown header-item">
              <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="bx bx-search fs-22"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown">
                <form class="p-3">
                  <div class="form-group m-0">
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="Search ..."
                        aria-label="Recipient's username" />
                      <button class="btn btn-primary" type="submit">
                        <i class="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div v-if="this.syncContact" class="me-3 header-item d-none d-sm-flex">
              <!-- Load More Buttons -->
              <span class="spinner-border flex-shrink-0" role="status" style="width: 1.2rem; height: 1.2rem;">
                <span class="visually-hidden">Loading...</span>
              </span>
            </div>

            <!-- Status Agent -->
            <div class="ms-1 header-item d-none d-sm-flex" v-if="this.isShowStatus">
              <i class="ri-information-line me-2" style="font-size: 15px;" data-bs-toggle="tooltip" data-bs-placement="right" title="Status Aktif merupakan status dimana ketika ada customer baru chat akan auto ke assigned, jika di matikan akan membuat anda tidak muncul di kumpulan random agent auto assigned"></i>
              <label class="form-check-label me-3">Status Active</label>
              <div class="form-check form-switch form-switch-lg" dir="ltr">
                <input type="checkbox" class="form-check-input" id="customSwitchsizelg" :checked="this.statusActivate === 'on'" @change="setStatus($event)">
              </div>
            </div>
  
            <!-- dark mode -->
            <div class="ms-1 header-item d-none d-sm-flex">
              <button type="button" class=" btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode shadow-none"
                @click="toggleDarkMode">
                <i class="bx bx-moon fs-22"></i>
              </button>
            </div>
  
            <!-- notification -->
            <div class="dropdown topbar-head-dropdown ms-1 header-item">
              <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
                id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="bx bx-bell fs-22"></i>
                <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                  {{ !this.notifData ? 0 : this.notifData.length }}<span class="visually-hidden">unread messages</span></span>
              </button>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown">
                <div class="dropdown-head bg-primary bg-pattern rounded-top">
                  <div class="p-3">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="m-0 fs-16 fw-semibold text-white">
                          Notifications
                        </h6>
                      </div>
                      <div class="col-auto dropdown-tabs">
                        <span class="badge badge-soft-light fs-13"> {{ this.notifData.length }} New</span>
                      </div>
                    </div>
                  </div>
  
                  <div class="px-2 pt-2">
                    <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
                      id="notificationItemsTab" role="tablist">
                      <!-- <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab"
                          aria-selected="false" @click.capture.stop>
                          All (4)
                        </a>
                      </li> -->
                      <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="true"
                          @click.capture.stop>
                          Messages
                        </a>
                      </li>
                      <!-- <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab" aria-selected="false"
                          @click.capture.stop>
                          Alerts
                        </a>
                      </li> -->
                    </ul>
                  </div>
                </div>
  
                <div class="tab-content" id="notificationItemsTabContent">
  
                  <div class="tab-pane fade py-2 ps-2 show active" id="all-noti-tab" role="tabpanel">
                    <SimpleBar data-simplebar style="max-height: 300px" class="pe-2" v-if="this.notifData.length > 0" >
                        
                      <div v-for="data in notifData" :key="data.id.id" class="text-reset notification-item d-block dropdown-item position-relative">
                        <div class="d-flex">
                          <img src="@/assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs"
                            alt="user-pic" />
                          <div class="flex-1">
                            <a href-link="" @click="openChat(data)" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                {{ data._data.notifyName }}
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1" v-if="!data.hasMedia">
                                {{ data.body }}
                              </p>
                            </div>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span><i class="mdi mdi-clock-outline"></i> {{ this.chatTime(data.timestamp) }} </span>
                            </p>
                          </div>
                          <!-- <div class="px-2 fs-15">
                            <input class="form-check-input" type="checkbox" />
                          </div> -->
                        </div>
                      </div>

  
                      <!-- <div class="my-3 text-center">
                        <button type="button" class="btn btn-soft-success">
                          View All Notifications
                          <i class="ri-arrow-right-line align-middle"></i>
                        </button>
                      </div> -->
                    </SimpleBar>
                  </div>
  
                  <div class="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">
                    <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                      <div class="text-reset notification-item d-block dropdown-item">
                        <div class="d-flex">
                          <img src="@/assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs"
                            alt="user-pic" />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                James Lemire
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                We talked about a project on linkedin.
                              </p>
                            </div>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span><i class="mdi mdi-clock-outline"></i> 30 min ago</span>
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <input class="form-check-input" type="checkbox" />
                          </div>
                        </div>
                      </div>
  
                      <div class="text-reset notification-item d-block dropdown-item">
                        <div class="d-flex">
                          <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs"
                            alt="user-pic" />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Angela Bernier
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                Answered to your comment on the cash flow
                                forecast's graph 🔔.
                              </p>
                            </div>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span><i class="mdi mdi-clock-outline"></i> 2 hrs
                                ago</span>
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <input class="form-check-input" type="checkbox" />
                          </div>
                        </div>
                      </div>
  
                      <div class="text-reset notification-item d-block dropdown-item">
                        <div class="d-flex">
                          <img src="@/assets/images/users/avatar-6.jpg" class="me-3 rounded-circle avatar-xs"
                            alt="user-pic" />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Kenneth Brown
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                Mentionned you in his comment on 📃 invoice
                                #12501.
                              </p>
                            </div>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span><i class="mdi mdi-clock-outline"></i> 10 hrs
                                ago</span>
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <input class="form-check-input" type="checkbox" />
                          </div>
                        </div>
                      </div>
  
                      <div class="text-reset notification-item d-block dropdown-item">
                        <div class="d-flex">
                          <img src="@/assets/images/users/avatar-8.jpg" class="me-3 rounded-circle avatar-xs"
                            alt="user-pic" />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Maureen Gibson
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                We talked about a project on linkedin.
                              </p>
                            </div>
                            <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span><i class="mdi mdi-clock-outline"></i> 3 days
                                ago</span>
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <input class="form-check-input" type="checkbox" />
                          </div>
                        </div>
                      </div>
  
                      <div class="my-3 text-center">
                        <button type="button" class="btn btn-soft-success">
                          View All Messages
                          <i class="ri-arrow-right-line align-middle"></i>
                        </button>
                      </div>
                    </SimpleBar>
                  </div>
  
                  <div class="tab-pane fade p-4" id="alerts-tab" role="tabpanel" aria-labelledby="alerts-tab">
                    <div class="w-25 w-sm-50 pt-3 mx-auto">
                      <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                    </div>
                    <div class="text-center pb-5 mt-2">
                      <h6 class="fs-18 fw-semibold lh-base">
                        Hey! You have no any notifications
                      </h6>
                    </div>
                  </div>
  
                </div>
              </div>
            </div>
  
            <div class="dropdown ms-sm-3 header-item topbar-user">
              <button type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="d-flex align-items-center">
                  <img id="avatarNavbar" class="rounded-circle header-profile-user" :src="avatar"
                    alt="Header Avatar" />
                  <span class="text-start ms-xl-2">
                    <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{user}}</span>
                    <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{role}}</span>
                  </span>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <h6 class="dropdown-header">Welcome {{user}}!</h6>
                <router-link class="dropdown-item" to="/pages/profile"><i
                    class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Profile</span>
                </router-link>
                <router-link class="dropdown-item" to="/chat">
                  <i class=" mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Messages</span>
                </router-link>
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/pages/profile-setting"><i
                    class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle">Settings</span>
                </router-link>
                <a class="dropdown-item" href="/logout"><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                  <span class="align-middle" data-key="t-logout">Logout</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </template>